// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../stylesheets/application'

import toastr from 'toastr'

import { Popover, Sidebar } from '@coreui/coreui'
import 'select2/dist/js/select2'

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('jquery')

window.jQuery = $
window.$ = $

toastr.options.closeButton = true
toastr.options.showMethod = 'slideDown'
toastr.options.progressBar = true
window.toastr = toastr

document.addEventListener('turbolinks:load', function () {
  $('.c-sidebar-nav-dropdown-items').each((_, item) => $(item).children('li').length === 0 ? $(item).parent().hide() : null)

  Array.from(document.querySelectorAll('[data-toggle="popover"]'))
    .forEach(popoverNode => new Popover(popoverNode))

  Array.from(document.querySelectorAll('.c-sidebar')).forEach(element => {
    Sidebar._sidebarInterface(element)
  })

  $('.js-select2').select2()
})
